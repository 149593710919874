import { QuestionnaireAnswer } from '@capturi/api-conversations'
import { VStack } from '@chakra-ui/react'
import { FC, useCallback, useMemo } from 'react'

import {
  useDeleteAnswer,
  usePublishedQuestionnaires,
} from 'features/questionnaire'
import { QAAnswerItem } from './QAAnswerItem'

type QAAnswersListProps = {
  conversationUid: string
  answers: QuestionnaireAnswer[]
}

export const QAAnswersList: FC<QAAnswersListProps> = ({
  conversationUid,
  answers,
}) => {
  const { questionnairesMap } = usePublishedQuestionnaires()
  const { mutate: deleteAnswer } = useDeleteAnswer({
    conversationUid,
  })

  const answersWithQuestionnaires = useMemo(
    () =>
      answers.map((answer) => ({
        answer,
        questionnaireListItem: questionnairesMap[answer.questionnaireUid],
      })),
    [answers, questionnairesMap],
  )

  const handleDelete = useCallback(
    (answerUid: string, questionnaireUid: string) => {
      deleteAnswer({
        questionnaireUid,
        answerUid,
      })
    },
    [deleteAnswer],
  )

  return (
    <VStack spacing={2} width="100%" align="stretch">
      {answersWithQuestionnaires.map(({ answer, questionnaireListItem }) => (
        <QAAnswerItem
          key={answer.uid}
          answer={answer}
          questionnaireListItem={questionnaireListItem}
          conversationUid={conversationUid}
          onDelete={handleDelete}
        />
      ))}
    </VStack>
  )
}
