import { QuestionAnswer, QuestionnaireAnswer } from '@capturi/api-conversations'
import { useCurrentUser } from '@capturi/core'
import { useUsers } from '@capturi/stores'
import { useConfirmCallback } from '@capturi/use-confirm'
import {
  Box,
  Collapse,
  HStack,
  IconButton,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { FC, memo, useCallback, useState } from 'react'
import { MdDelete, MdEdit, MdFlag, MdKeyboardArrowDown } from 'react-icons/md'

import {
  LazyQuestionnairePreview,
  QaQuestionnaireListItem,
  useUpdateAnswer,
} from 'features/questionnaire'

const formatDate = (d: Date): string => {
  return i18n.date(d, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
}

type QAAnswerItemProps = {
  answer: QuestionnaireAnswer
  questionnaireListItem: QaQuestionnaireListItem
  conversationUid: string
  onDelete: (answerUid: string, questionnaireUid: string) => void
}

export const QAAnswerItem: FC<QAAnswerItemProps> = memo(
  ({ answer, questionnaireListItem, conversationUid, onDelete }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const { getUserByUid } = useUsers()
    const { isOwner, id } = useCurrentUser()
    const showEditButtons = isOwner || answer.createdByUserUid === id
    const toast = useToast()

    const { mutate: updateAnswer } = useUpdateAnswer({
      conversationUid,
    })

    const showConfirm = useConfirmCallback()

    const handleDelete = useCallback(() => {
      showConfirm({
        options: {
          title: t`Delete questionnaire answer?`,
          description: t`This action cannot be undone. The questionnaire answer will be permanently deleted.`,
          confirmText: t`Delete`,
          colorScheme: 'red',
        },
        onConfirm: () => {
          onDelete(answer.uid, questionnaireListItem.uid)
        },
      })
    }, [onDelete, answer.uid, questionnaireListItem.uid, showConfirm])

    const handleEdit = useCallback(() => {
      setIsEditing(true)
      setIsOpen(true)
    }, [])

    const handleSubmit = useCallback(
      (updates: Record<string, QuestionAnswer>) => {
        updateAnswer(
          {
            questionnaireUid: answer.questionnaireUid,
            answerUid: answer.uid,
            updates,
          },
          {
            onSuccess: () => {
              setIsEditing(false)
              toast({
                title: t`Answer has been updated`,
                status: 'success',
              })
            },
            onError: () => {
              toast({
                title: t`Failed to update questionnaire`,
                status: 'error',
              })
            },
          },
        )
      },
      [answer.uid, answer.questionnaireUid, updateAnswer, toast],
    )

    const toggleOpen = useCallback(() => {
      setIsOpen((prev) => !prev)
    }, [])

    const creator = getUserByUid(answer.createdByUserUid)

    return (
      <Box
        borderWidth="1px"
        borderColor="gray.200"
        borderRadius="base"
        w="100%"
      >
        <HStack justifyContent="space-between" px={3} py={2}>
          <HStack spacing={2}>
            <Box fontSize="lg" color="gray.600">
              <MdFlag />
            </Box>
            <VStack spacing={0} alignItems="flex-start">
              <Text fontSize="sm">{questionnaireListItem?.title}</Text>
              <Text fontSize="xs" color="gray.600">
                {creator?.name} {formatDate(answer.created)}
              </Text>
            </VStack>
          </HStack>

          <HStack spacing={4}>
            {showEditButtons && (
              <HStack spacing={1}>
                <IconButton
                  aria-label="Delete review"
                  icon={<MdDelete />}
                  size="sm"
                  variant="ghost"
                  onClick={handleDelete}
                />
                <IconButton
                  aria-label="Edit review"
                  icon={<MdEdit />}
                  size="sm"
                  variant="ghost"
                  onClick={handleEdit}
                />
              </HStack>
            )}
            <IconButton
              aria-label={isOpen ? t`Collapse review` : t`Expand review`}
              icon={
                <Box
                  as="span"
                  transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
                  transition="transform 0.2s ease-in-out"
                >
                  <MdKeyboardArrowDown />
                </Box>
              }
              size="sm"
              variant="ghost"
              onClick={toggleOpen}
            />
          </HStack>
        </HStack>

        <Collapse in={isOpen} animateOpacity unmountOnExit>
          <Box p={4} bg="gray.50">
            <LazyQuestionnairePreview
              questionnaireUid={questionnaireListItem.uid}
              initialAnswers={answer.answers}
              isDisabled={!isEditing}
              showSubmitButton={isEditing}
              onSubmit={handleSubmit}
            />
          </Box>
        </Collapse>
      </Box>
    )
  },
)
