import {
  ConversationResponseModel,
  QuestionnaireAnswer,
} from '@capturi/api-conversations'
import { useFeatureFlags } from '@capturi/feature-flags'
import { useUsers } from '@capturi/stores'
import { Divider, HStack, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { Hit } from '../Audio/types'
import CommentsSection from './Comments/CommentsSection'
import { QAAnswersList } from './QAAnswers/QAAnswersList'
import StatisticsView from './Statistics/StatisticsView'

type CoachingView = {
  conversation: ConversationResponseModel
  agentName?: string
  setHits: (hits: Hit[]) => void
}

const QuestionnaireSection = ({
  questionnaireAnswers,
  conversationUid,
}: {
  questionnaireAnswers: QuestionnaireAnswer[] | undefined
  conversationUid: string
}) => {
  const { enableQAQuestionnaire } = useFeatureFlags()
  if (
    !enableQAQuestionnaire ||
    !questionnaireAnswers ||
    questionnaireAnswers.length === 0
  ) {
    return null
  }

  return (
    <>
      <VStack align="stretch" gap="2">
        <Text fontWeight="medium">
          <Trans>Reviews</Trans>
        </Text>
        <QAAnswersList
          conversationUid={conversationUid}
          answers={questionnaireAnswers}
        />
      </VStack>
      <Divider />
    </>
  )
}

const CoachingView: React.FC<CoachingView> = ({ conversation, setHits }) => {
  const { getUserByUid } = useUsers()

  const questionnaireAnswers = conversation.questionnaireAnswers
  return (
    <VStack align="stretch" gap="8">
      <QuestionnaireSection
        conversationUid={conversation.uid}
        questionnaireAnswers={questionnaireAnswers}
      />
      <HStack align="start" gap="6">
        <StatisticsView
          conversation={conversation}
          agentName={getUserByUid(conversation.userUid).name}
          key={getUserByUid(conversation.userUid).profileImage?.key}
        />
        <CommentsSection conversation={conversation} setHits={setHits} />
      </HStack>
    </VStack>
  )
}

export default CoachingView
